import React, {  } from 'react'
import TargetDiscovery from "src/assembled/Technology/TargetDiscovery"
import { Footer } from "src/components"
import { SEO } from "src/components/utils"


export default function Technology({ location }) {

    // seo
    const seo = <SEO
    title="Technology"
    description="Introduce AI-Powered Target-Discovery Technology of Deargen Inc." 
    pathname={location.pathname} />


    // render
    const render = <main role="main">
        <TargetDiscovery location={location} />
    </main>


    return <>
        {seo}
        {render}
        <Footer location={location} />
    </>
}
